.whiteinput {
  color: #fff !important;
  font-family: 'IRANSans' !important;
}
.blackinput {
  color: black !important;
  font-family: 'IRANSans' !important;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #afb6d055;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #afb6d0;
}

.requiredFieldStar {
  color: red;
}

textarea,
input {
  font-family: IRANSans !important;
}
